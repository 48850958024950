<template>
  <div style="position: relative" class="doceration">
    <div class="potential">
<!--      <van-nav-bar style="background-color: #f8f8f8" title="装修质量验收" left-arrow @click-left="leftReturn" fixed/>-->
      <rxNavBar title="装修质量验收"></rxNavBar>
      <!--上传图片loading-->
      <loading v-if="loadingFlag"></loading>
      <p style="margin-bottom: 10px"></p>
      <div class="panel">
        <div class="state">待审批</div>
        <div class="title">
          <p class="van-multi-ellipsis--l2">{{ contractInfo.dispatchAddress }}</p>
        </div>
        <van-divider/>
        <van-row class="info">
          <van-col span="12">
            <p>合同编号：<span>{{ contractInfo.contractCode }}</span></p>
          </van-col>
          <van-col span="12">
            <p>收房年限：<span>{{ contractInfo.contractYears }}年</span></p>
          </van-col>
          <van-col span="12">
            <p>业主姓名：<span>{{ contractInfo.ownerName }}</span></p>
          </van-col>
          <van-col span="12">
            <p>面积：<span>{{ contractInfo.builtUpArea }}㎡</span></p>
          </van-col>
        </van-row>
      </div>
      <p class="subtitle">申请详情</p>
      <div class="panel">
        <van-row class="info">
          <van-col span="24">
            <p>申请人：<span>{{ apply.applicant }} {{ apply.applicantMobile }}</span></p>
          </van-col>
          <van-col span="24">
            <p>设计师：<span>{{ apply.designGlobaluserName }}</span></p>
          </van-col>
          <van-col span="12">
            <p>户型改造：<span v-if="apply.type1&&apply.type2">{{ apply.type1 }}+{{ apply.type2 }}</span></p>
          </van-col>
          <van-col span="12">
            <p>费用承担：<span>{{ apply.costBearStr }}</span></p>
          </van-col>
        </van-row>
      </div>
      <!-- 图片墙 -->
      <div v-if="0<imagesList.length" class="panel withMargin">
        <div class="swiper_show" ref="wrapperBox">
          <div class="content">
            <div class="imgWrap" v-for="(url,i) in imagesList" @click="previewImg(i)">
              <van-image width="100"  fit="contain" style="background-color: #e1eaec;" height="75"  radius="10" :src="url"/>
            </div>
          </div>
          <div class="num">
            {{ imagesList.length }}图
          </div>
        </div>
      </div>

      <div class="panel">
        <van-tabs v-model="active" line-width="20" line-height="5" title-active-color="rgb(255,81,45)" swipeable sticky>
          <van-tab title="硬装验收">
            <!-- 硬装方案 -->
            <van-row class="info noPadding">
              <van-col span="24">
                <p>硬装负责人：<span>{{ apply.hardChargeName }}</span></p>
              </van-col>
              <van-col span="24">
                <p>实际开始时间：<span>{{ apply.hardBeginTime }}</span></p>
              </van-col>
              <van-col span="24">
                <div id="hardTime" class="overDiv" @click="clickHardTimeShow">
                  <div style="white-space: nowrap">实际结束时间：</div>
                  <span style="width: 100%">{{ apply.hardEndTime | formatDateTime }}</span>
                  <span class="calendar_icon"></span>
                </div>
                <div>
<!--                  <div class="datepicker" v-if="isHardDateShow">-->
<!--                    <van-datetime-picker-->
<!--                        v-model="currentDate"-->
<!--                        type="date"-->
<!--                        title="选择年月日"-->
<!--                        item-height="35px"-->
<!--                        @confirm="hardDateConfirm"-->
<!--                        @cancel="isHardDateShow = false"-->
<!--                    />-->
<!--                  </div>-->
                  <van-popup v-model="isHardDateShow" position="bottom">
                    <van-datetime-picker
                        v-model="currentDate"
                        type="date"
                        title="选择年月日"
                        item-height="35px"
                        @confirm="hardDateConfirm"
                        @cancel="isHardDateShow = false"
                    />
                  </van-popup>
                </div>
              </van-col>
              <van-col span="24">
                <p>预计合计费用：<span>{{ apply.handBudgetFee }}元</span></p>
              </van-col>
              <van-col span="24">
                <p>实际合计费用：<span class="total_color">{{ apply.hardRealFee }}元</span></p>
              </van-col>
            </van-row>
            <van-divider/>
            <div class="t_table">
              <van-row style="background-color: #f4f8ff;height: 20px">
                <van-col style="line-height: 20px" span="8" class="t_header">项目名称</van-col>
                <van-col style="line-height: 20px" span="6" class="t_header">数量</van-col>
                <van-col style="line-height: 20px" span="10" class="t_header">实际费用（预计费用）</van-col>
              </van-row>
              <div class="t_body" v-for="(items,index) in hardwareList" :key="index">
                <van-row>
                  <span class="diamod"></span>
                  <span class="t_title">{{ items[0].goodsAllocationClassName }}</span>
                  <van-row v-for="(item,i) in items" :key="i">
                    <van-col span="8" class="t_td" style="text-align: unset;padding-left: 30px;">{{ item.itemName }}
                    </van-col>
                    <van-col span="6" class="t_td">{{ item.totalCount }}</van-col>
                    <van-col span="10" class="t_td">{{ Number(item.realMoney).toFixed(2) }}元<span
                        style="color:rgb(136,136,136) ">（{{ item.estimateMoney }}）</span></van-col>
                  </van-row>
                </van-row>
              </div>

            </div>
          </van-tab>
          <van-tab title="软装验收">
            <van-row class="info noPadding">
              <van-col span="24">
                <p>软装负责人：<span>{{ apply.softChargeName }}</span></p>
              </van-col>
              <van-col span="24">
                <p>实际开始时间：<span>{{ apply.softBeginTime }}</span></p>
              </van-col>
              <van-col span="24">
                <div id="softTime" class="overDiv" @click="clickSoftTimeShow">
                  <div style="white-space: nowrap">实际结束时间：</div>
                  <span style="width: 100%">{{ apply.softEndTime | formatDateTime }}</span>
                  <span class="calendar_icon"></span>
                </div>
                <div>
<!--                  <div class="datepicker" v-if="isSoftDateShow">-->
<!--                    <van-datetime-picker-->
<!--                        v-model="currentDate"-->
<!--                        type="date"-->
<!--                        title="选择年月日"-->
<!--                        item-height="35px"-->
<!--                        @confirm="softDateConfirm"-->
<!--                        @cancel="isSoftDateShow = false"-->
<!--                    />-->
<!--                  </div>-->
                  <van-popup v-model="isSoftDateShow" position="bottom">
                    <van-datetime-picker
                        v-model="currentDate"
                        type="date"
                        title="选择年月日"
                        item-height="35px"
                        @confirm="softDateConfirm"
                        @cancel="isSoftDateShow = false"
                    />
                  </van-popup>
                </div>
              </van-col>
              <van-col span="24">
                <p>预计合计费用：<span>{{ apply.softBudgetFee }}元</span></p>
              </van-col>
              <van-col span="24">
                <p>实际合计费用：<span class="total_color">{{ apply.softRealFee }}元</span></p>
              </van-col>
            </van-row>
            <van-divider/>
            <div class="t_table">
              <van-row style="background-color: #f4f8ff;height: 20px">
                <van-col style="line-height: 20px" span="8" class="t_header">项目名称</van-col>
                <van-col style="line-height: 20px" span="6" class="t_header">数量</van-col>
                <van-col style="line-height: 20px" span="10" class="t_header">实际费用（预计费用）</van-col>
              </van-row>
              <div class="t_body" v-for="(items,index) in softwareList" :key="index">
                <van-row>
                  <span class="diamod"></span>
                  <span class="t_title">{{ items[0].roomName }}</span>
                  <van-row v-for="(item,i) in items" :key="i">
                    <van-col span="8" class="t_td" style="text-align: unset;padding-left: 30px;">{{ item.itemName }}
                    </van-col>
                    <van-col span="6" class="t_td">{{ item.totalCount }}</van-col>
                    <van-col span="10" class="t_td">{{ Number(item.realMoney).toFixed(2) }}元<span
                        style="color:rgb(136,136,136) ">（{{ item.estimateMoney }}）</span></van-col>
                  </van-row>
                </van-row>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>

      <div class="panel withMargin">
        <van-row>
          <van-col span="24" class="info">
            <p class="allprice">预计总装修款：<span class="floatR">{{Number(apply.budgetFee).toFixed(2)}}元</span></p>
            <p>实际总装修款：<span class="floatR">{{Number(apply.totalRealFee).toFixed(2)}}元</span></p>
          </van-col>
        </van-row>
      </div>
      <div v-if="checkAuthList(authButtonsList,'specialCheckAcceptance')">
        <p class="subtitle">品质专员验收</p>
        <div class="panel radio_body withMargin newMarginStyle1">
          <p style="marginBottom:15px">
            <span :class="isPass !== '' ? 'dot is_dot' : 'dot'"></span>
            审批状态
          </p>
          <div class="choose">
            <div :class="isPass === 1?'choose_btn pass': 'choose_btn'" @click="isPass = 1">通过</div>
            <div class="line"></div>
            <div :class="isPass === 0?'choose_btn pass': 'choose_btn'" @click="isPass = 0">驳回</div>
          </div>
        </div>

        <div id="addressPanel" class="part-inputpart-row" v-if="isPass === 0" @click="isReasonShow = true">
          <span class="maker" :class="{'orangeColor':refuseReasonArr[refuseReasonIndex]}">• </span>
          <span class="part-inputpart-row-header">原因</span>
          <span class="content-divide">|</span>
          <span class="part-inputpart-row-graytext" :class="refuseReasonArr[refuseReasonIndex] ? 'part-inputpart-row-normaltext':'' ">{{ refuseReasonArr[refuseReasonIndex] ? refuseReasonArr[refuseReasonIndex].dictionaryTitle : '' }}</span>
          <img class="part-inputpart-row-right"
               :class=" isReasonShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
               src="../../../assets/images/triangle.png">
        </div>
        <!--            <div>-->
        <!--              <div class="part-inputpart part-inputpart-dropDownList" v-if="isReasonShow">-->
        <!--                <div class="part-inputpart-dropDownList-option" :class="item==rejectReason ? 'part-inputpart-dropDownList-option-selected' : '' "-->
        <!--                     v-for="(item,index) in refuseReasonList" :key="index" @click="selectAddressOption($event,index)">{{ item.dictionaryTitle}}</div>-->
        <!--              </div>-->
        <!--            </div>-->
        <div class="panel">
          <p>{{ isPass === 0  ? '描述' : '备注' }}</p>
          <div class="text_body">
            <van-field
                v-model="option"
                autosize
                type="textarea"
                class="mes_body"
            />
          </div>
        </div>
        <div class="panel withMargin" v-if="isPass !== 0">
          <van-row>
            <van-col span="24" class="info">
              <p>服务人员评价：<van-rate v-model="star" count="5" color="#FF5D3B" style="position: absolute;bottom: 18px;"/></p>
            </van-col>
          </van-row>
        </div>
        <div class="part">
          <div class="part-inputpart">
            <div class="part-uploadpic-content">
              <!--上传图片按钮-->
              <van-uploader v-model="imgList"
                            upload-icon="plus"
                            :max-count="50"
                            multiple
                            :after-read="afterReadTest"
              >
              </van-uploader>
            </div>
          </div>
        </div>
        <div class="sub_btn">
          <van-button :class="isPass===1 || isPass===0&&refuseReasonIndex !== '' ?'sub_button':'sub_button not_choose'" :disabled="!(isPass===1 || isPass===0&&refuseReasonIndex !== '')" size="large" @click="save">
            保存
          </van-button>
        </div>
      </div>
    </div>
    <div v-if="checkAuthList(authButtonsList,'acceptApprove')" class="dividingLine"></div>
    <van-popup v-model="isReasonShow" position="bottom">
      <van-picker
          show-toolbar
          :columns="refuseReasonArr"
          @cancel="isReasonShow = false"
          :default-index="refuseReasonIndex"
          value-key="dictionaryTitle"
          @confirm="selectRefuseReason"
      />
    </van-popup>
  </div>
</template>
<script>
import BScroll from 'better-scroll';
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";

import {
  NavBar,
  Tab,
  Tabs,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  Tag,
  Button,
  Popup,
  Field,
  Divider,
  Image as VanImage,
  ImagePreview,
  Rate, DatetimePicker, Uploader, Picker
} from 'vant'
import {
  base64Upload,
  decorationConstructionDetails,
  queryBaseData,
  queryRenovation,
  showProgramme
} from "../../../getData/getData";
import {globaluserId, responseUtil,checkAuthList} from "../../../libs/rongxunUtil";
import {getStaffFuntionModelList, saveAcceptance} from "../../../getData/getData";
import {getStaffId} from "../../../libs/rongxunUtil";

export default {
  data() {
    return {
      loadingFlag:false,//上传图片loading
      star: 0,
      isSoftDateShow: false,
      isHardDateShow: false,
      currentDate: new Date(),
      rtid: this.$route.query.rtid || '',
      hardwareList: [],
      softwareList: [],
      contractInfo: {},
      apply: {
        hardBeginTime: '',
        hardEndTime: '',
        softBeginTime: '',
        softEndTime: ''
      },
      imagesList: [],
      isReasonShow: false,
      isPass: '',
      option: '',
      active: 0,
      imgList: [],
      refuseReasonArr: [],
      refuseReasonIndex: '',
      authButtonsList: [],
    }
  },

  watch: {
    'apply.hardEndTime'(newVal,oldVal){
      if(this.apply.hardBeginTime && new Date(this.apply.hardBeginTime) >= newVal){
        responseUtil.alertMsg(this,'结束时间不能早于于开始时间')
        this.apply.hardEndTime = oldVal
      }
    },
    'apply.softEndTime'(newVal,oldVal){
      if(this.apply.softBeginTime && new Date(this.apply.softBeginTime) >= newVal){
        responseUtil.alertMsg(this,'结束时间不能早于于开始时间')
        this.apply.softEndTime = oldVal
      }
    },
  },

  mounted() {
    this.getStaffFuntionModelList()
  },

  name: 'doceration',
  components: {
    [NavBar.name]: NavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Row.name]: Row,
    [Col.name]: Col,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [Divider.name]: Divider,
    [VanImage.name]: VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Rate.name]: Rate,
    [DatetimePicker.name]: DatetimePicker,
    [Uploader.name]: Uploader,
    [Picker.name]: Picker,
    rxNavBar,
  },
  filters: {
    formatDateTime(value) {
      if (null == value || '' == value) return
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      // return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
      return y + '-' + MM + '-' + d
    }
  },
  methods: {

    getStaffFuntionModelList () {
      var that = this
      let data = {}
      data.staff_id = getStaffId()
      data.menuName = 'afterRentDecoration_index'
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.authButtonsList = response.data.data.data
        })
      })
    },
    checkAuthList,

    selectRefuseReason(value,index) {
      if(value)
      this.refuseReasonIndex = index
      this.isReasonShow = false
    },

    save() {
      let that = this
      let data = {}

      data.user_id = getStaffId()//globaluserId()
      data.userType = 1
      data.rtid = that.rtid
      if (that.isPass===0){
        data.radioSelect = '驳回'
        data.rejectionReason = that.refuseReasonIndex
        data.describe = that.option
      }
      if (that.isPass===1){
        data.radioSelect = '通过'
        data.costBear = that.apply.costBear
        data.remarks = that.option
        data.pingjia = that.star
      }
      if(that.imgList && that.imgList.length){
        data.picList = []
        for (let i in that.imgList)
          data.picList.push({path:that.imgList[i].url})
      }
      data.hardEndTime = new Date(that.apply.hardEndTime)
      data.softEndTime = new Date(that.apply.softEndTime)
      // console.log(data)
      // return
      saveAcceptance(data).then(function (response){
        responseUtil.dealResponse(that,response,()=>{
          console.log(response)
          if(200 == response.status){
            responseUtil.alertMsg(that,'操作成功')
            that.$router.replace('repairPending')
          }
        })
      })
    },

      //物品配置图片多选上传
      afterReadTest(file){
          // this.loadingFlag = true;
          let fileLength = parseInt(file.length) - parseInt(1)
          if(file.length > 1){
              for(let i = 0; i<file.length;i++){
                  let fileDetail = file[i];
                  this.afterRead(fileDetail,i,fileLength);
              }
          }else{
              this.afterRead(file);
          }
      },

      afterRead(file,i,fileLength) {
          let that = this
          let initData = {
              base64 : file.content
          }
          that.loadingFlag = true;
          base64Upload(initData).then(function (response) {
              that.loadingFlag = false;
              responseUtil.dealResponse(that, response, () => {
                  file.url = response.data.data.path
                  console.log(that.picList)
                  if(i == fileLength){
                      that.loadingFlag = false
                  }
              })
          })
      },

    clickHardTimeShow() {
      this.isHardDateShow = !this.isHardDateShow;
      var panel = document.getElementById('hardTime')
      if (panel) {
        document.addEventListener('click', e => {
          if (!panel.contains(e.target)) {
            this.isHardDateShow = false
          }
        })
      }
    },

    hardDateConfirm(value) {
      this.apply.hardEndTime = value
      this.isHardDateShow = false
    },
    clickSoftTimeShow() {
      this.isSoftDateShow = !this.isSoftDateShow;
      var panel = document.getElementById('softTime')
      if (panel) {
        document.addEventListener('click', e => {
          if (!panel.contains(e.target)) {
            this.isSoftDateShow = false
          }
        })
      }
    },

    softDateConfirm(value) {
      this.apply.softEndTime = value
      this.isSoftDateShow = false
    },
    initData() {
      var that = this
      let initData = {}
      initData.rtid = that.rtid;
      initData.user_id = globaluserId()
      showProgramme(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data)
          that.contractInfo = response.data.data.contractInfo
          that.apply = response.data.data.headMap
          if (response.data.data.pathList && response.data.data.pathList.length) {
            for (let i in response.data.data.pathList)
              that.imagesList.push(response.data.data.pathList[i].path)
            that.$nextTick(() => {
              if (!that.scroll) {
                that.scroll = new BScroll(that.$refs.wrapperBox, {
                  scrollX: true,
                  eventPassthrough: 'vertical'
                })
              }
            })
          }
          let hP = response.data.data.handProgrammeList
          let obj = {}
          hP.forEach(function (item) {
            // if(obj[item.goodsClassId]){
            //   obj[item.goodsClassId].push(item)
            // }else {
            //   obj[item.goodsClassId] = [];
            //   obj[item.goodsClassId].push(item)
            // }
            let arr = obj[item['goodsClassId']] || []
            arr.push(item)
            obj[item['goodsClassId']] = arr
          })
          that.hardwareList = Object.values(obj)
          //console.log(Object.values(obj))
          let sP = response.data.data.softProgrammeList
          let sobj = {}
          sP.forEach(function (item) {
            let arr = sobj[item['roomHouseId']] || []
            arr.push(item)
            sobj[item['roomHouseId']] = arr
          })
          that.softwareList = Object.values(sobj)

        })
      })
    },

    //初始化字典数据
    initDicData:function(){
      const that = this
      let initData = {}
      initData.dbName = ['refuseReason']
      //initData.fdName = ['RESOURCEBELOGMAP']
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.refuseReasonArr = response.data.data.refuseReason
        })
      })
    },

    previewImg(start) {
      ImagePreview({
        images: this.imagesList,
        startPosition: start,
        onClose() {
          // do something
        },
      })
    },
    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },
  },
  created() {
    this.initData()
    this.initDicData()
  }
}
</script>
<style lang="less" scoped>
@redius: 8px;
@border: 1px solid rgb(244, 244, 244);

.content-divide {
  font-size: 14px;
  color: #efefef;
  margin: 0 10px;
}

.content-divide-small {
  font-size: 14px;
  // color: #9a9a9a;
  margin: 0 5px;
}

.maker {
  color: #999999;
  float: left;
  padding-left: 14px;
  padding-right: 8px;
  font-size: 28px;
}

.orangeColor {
  color: #ff3c00;
}

/*css*/
.part-uploadpic-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  padding-top: 8px;
  border-radius: 8px;
}

.part {
  background-color: white;
  border-radius: 8px;
}

.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
}

.part-inputpart-row-right-upArrow {
  width: 9px;
  transform: rotateX(180deg);
}

.part-inputpart-row-header {
  font-weight: bold;
  font-size: 15px;
  width: 13%;
}


.part-inputpart-row-graytext {
  color: #d8d8d8;
  width: 100%;
  font-size: 15px;
}

.part-inputpart-row-normaltext {
  color: black;
}

.part-inputpart-row-right {
  float: right;
  margin-right: 10px;
}

.part-inputpart-row-right-downArrow {
  width: 9px;
}

.part-inputpart-row {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-color: white;
  border-radius: 8px;
  width: 345px;
  margin: 14px auto;
}

.datepicker {
  position: absolute;
  z-index: 99;
  width: 100%;
  margin-left: -14px;
}

.diamod {
  width: 8px;
  height: 8px;
  background-color: #3B98FF;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: 0 7px 0 33px;
  display: inline-block;
}

.dividingLine {
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 129px;
  background-color: #D8D8D8;
}

.potential {
  width: 375px;
  background-color: rgb(250, 250, 250);
  padding: 0 15px;
  box-sizing: border-box;
  overflow: hidden;
  color: #252525;


  .sub_btn {
    margin: 30px 0 70px;

    .sub_button {
      border-radius: @redius;
      color: rgb(255, 255, 255);
      border: none;
      background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Firefox 3.6 - 15 */
      background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));

      &.not_choose {
        background: none;
        background-color: rgba(184, 184, 184, 0.2);
      }
    }
  }

  p {
    margin: 0px;
  }

  .application_time {
    text-align: right;
    width: 345px;
    font-size: 12px;
    color: rgb(136, 136, 136);
    padding: 2px 0 5px;
  }

  .subtitle {
    padding: 5px 0px;
    color: rgb(244, 99, 76);
    margin-top: 25px;
    font-weight: 700;
    font-size: 12px;
  }

  .overDiv {
    height: 23px;
    /*line-height: 10px;*/
    /*border: 1px solid red;*/
    display: flex;
    align-items: center;
    /*justify-content: center;*/
  }

  /*实际结束更改*/

  .overDiv > div {
    font-weight: bolder;
  }

  .overDiv > span {
    font-size: 13px;
    color: rgb(110, 110, 110);
  }

  .calendar_icon {
    display: inline-block;
    width: 20px;
    height: 15px;
    background: url(../../../assets/images/timepick.png) no-repeat center center;
    background-size: cover;
    background-position-y: -1px;
  }

  .panel {
    width: 345px;
    background-color: rgb(255, 255, 255);
    border-radius: @redius;
    position: relative;
    font-size: 14px;
    text-align: left;
    overflow: hidden;


    &.withMargin {
      margin: 16px 0;
    }

    & > p {
      padding: 15px 15px 0px;
      font-weight: bolder;
      /*color: #252525;*/

    }

    & > p > span {
      font-weight: normal;
      color: rgb(190, 190, 190);
    }

    .state {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 20px;
      font-weight: bold;
      height: 32px;
      line-height: 32px;
      width: 60px;
      display: block;
      background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Firefox 3.6 - 15 */
      background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      border-top-left-radius: 16px;
      border-bottom-right-radius: 16px;
      color: rgb(255, 255, 255);
      text-align: center;
      transform: scale(0.5);
      transform-origin: 0% 0%;
      font-family: sans-serif;
      padding: 0 10px;
    }

    .title {
      width: 315px;
      padding: 0 15px;
      font-weight: bolder;
      color: rgb(0, 0, 0);
      font-size: 16px;
      padding-top: 20px;

      span {
        font-size: 12px;
        font-weight: normal;
        color: rgba(153, 153, 153, 1);
      }
    }

    .allprice {
      padding-bottom: 4px;
    }

    /*审核结果,服务人员 审批意见*/

    .shenhe, .fuwu, .yijian {
      font-size: 15px;
    }

    .describe {
      padding-bottom: 15px;
      padding-top: 10px;
      color: rgb(82, 82, 82);
      font-weight: normal;
    }

    .info {
      padding: 15px;
      line-height: 16px;

      &.noPadding {
        padding: 0 15px;
      }

      p {
        margin: 4px 0;
        font-weight: bolder;

        span {
          font-size: 13px;
          font-weight: normal;
          color: rgb(110, 110, 110);
        }
      }

      .floatR {
        float: right;
      }

      .total_color {
        color: rgba(255, 93, 59, 1);
      }
    }

    .text_body {
      padding: 15px;

      .mes_body {
        border-radius: @redius;
        background-color: rgb(250, 250, 250);
      }
    }
  }

  // 单选框
  .radio_body {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;

    .dot {
      display: inline-block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: rgb(136, 136, 136);
      vertical-align: middle;
      margin-right: 10px;
      margin-bottom: 6px;

      &.is_dot {
        background-color: rgb(251, 91, 64);
      }
    }

    .line {
      width: 2px;
      height: 12px;
      background-color: rgb(250, 250, 250);
    }

    .choose {
      margin-right: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;

      .choose_btn {
        padding: 5px;
        margin: 10px;
        color: rgb(136, 136, 136);
        cursor: pointer;

        &.pass {
          color: rgb(251, 91, 64);
          font-weight: bolder;
        }

        &.reject {
          color: red;
          font-weight: bolder;
        }
      }
    }
  }

  // 循环表单
  .t_table {
    .t_header {
      text-align: center;
      color: rgb(136, 136, 136);
      /*font-weight: bolder;*/
      font-size: 10px;
      height: 40px;
      line-height: 40px;
    }

    .t_title {
      background-color: white;
      color: #33ADFF;
      text-align: center;
      font-weight: 700;
      font-size: 12px;
      height: 20px;
    }

    .t_td {
      text-align: center;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
      height: 40px;
      line-height: 40px;
      font-size: 13px;
    }
  }

  // 图片墙
  .swiper_show {
    width: 325px;
    position: relative;
    margin: 15px 10px;
    height: 75px;

    .content {
      position: absolute;
      display: flex;
      justify-content: space-around;
      left: 0px;

      .imgWrap {
        margin-right: 10px;
      }
    }

    .num {
      position: absolute;
      left: 4px;
      top: 6px;
      font-size: 10px;
      background-color: rgb(0, 0, 0);
      opacity: .5;
      color: rgb(255, 255, 255);
      padding: 2px 6px;
      display: inline-block;
      border-radius: 10px;
      font-weight: bolder;
      line-height: 10px;
    }
  }
}
</style>
